<template lang="pug">
  .max-h-48.overflow-auto.scrollbar-thin.scrollbar-thumb-gray-200.scrollbar-track-transparent(
    class='-mx-0.5'
  )

    .symbol-suggestion(
      v-if='!items.length'
    ) Type and press ⏎ to create a symbol

    .symbol-suggestion.flex.whitespace-nowrap.overflow-hidden.cursor-pointer.text-sm.leading-snug.text-white.truncate(
      v-for="(item, index) in items"
      :class="[ \
        'pl-1 mb-0.5 last:mb-0', \
        { \
          'pr-6': !item.createNew, \
          'pr-0': item.createNew, \
          'font-bold': item.createNew, \
          'bg-black hover:bg-gray-900': index !== selectedIndex, \
          'bg-gray-800': index === selectedIndex, \
          'symbol-suggestion--selected': index === selectedIndex \
      }]"
      :key="index"
      :style='`--color: ${item.color}`'
      @click='clickHandler(item)'
    )
      template(v-if='item.save')
        PinOutlineIcon(:size='16')
        | {{ item.save }} ({{ item.name }})
      template(v-else)
        | {{ item.name }}
    </button>
  </div>
</template>

<script>
import { createId } from '@/util'

export default {
  props: {

    items: {
      type: Array,
      required: true
    },

    command: {
      type: Function,
      required: true
    },

    query: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      selectedIndex: 0
    }
  },

  mounted () {
    this.selectedIndex = 0
  },

  watch: {
    items (items) {
      const itemsCount = items.length
      const realItemsCount = items.filter(i => !i.createNew).length
      this.selectedIndex = this.query.length && realItemsCount > 0 && itemsCount > 1 ? 1 : 0
    }
  },

  computed: {
    selectedItem () {
      return this.items[this.selectedIndex] || null
    }
  },

  methods: {
    onKeyDown ({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }
      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }
      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }
      return false
    },

    upHandler () {
      this.selectedIndex = ((this.selectedIndex + this.items.length) - 1) % this.items.length
      this.scrollIntoView({ block: 'nearest' })
    },

    downHandler () {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length
      this.scrollIntoView({ block: 'nearest' })
    },

    enterHandler () {
      this.selectOrCreateSymbol(this.selectedItem)
    },

    clickHandler (item) {
      this.selectOrCreateSymbol(item)
    },

    scrollIntoView (alignToTop) {
      this.$nextTick(() => {
        const el = document.querySelector('.symbol-suggestion--selected')
        if (el) el.scrollIntoView(alignToTop)
      })
    },

    selectOrCreateSymbol (item) {
      if (!item) return

      if (item.createNew) {
        this.createSymbol(item)
        return
      }

      this.selectSymbol(item)
    },

    selectSymbol (symbol) {
      this.command(symbol)

      // make sure caret is in view
      // console.warn('test', this)
      // this.editor.commands.scrollIntoView()
    },

    async createSymbol (symbol) {
      if (!symbol.createNew.length) return

      const id = createId('s')
      const name = symbol.createNew
      const color = this.$randomColor()

      // select node in tipap first
      this.selectSymbol({ id, name, color, createNew: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.symbol-suggestion {
  border-left: 2px solid var(--color);
}
</style>
