import { Node } from '@tiptap/core'

export default Node.create({
  name: 'rule',
  content: 'inline*',
  // draggable: true, // prevents marking all text :(
  // selectable: true,
  // defining: true, // “this node should be taken into consideration when deciding what its content means” https://discuss.prosemirror.net/t/paste-behavior-research/469/27
  renderHTML ({ HTMLAttributes }) {
    return ['p', { ...HTMLAttributes, class: 'rule' }, 0]
  },
  parseHTML () {
    return [
      {
        tag: 'p',
        getAttrs: node => node.classList.contains('rule')
      }
    ]
  }
})
