import { Node } from '@tiptap/core'
import SetDocAttr from '@/components/editor/steps/SetDocAttr'

export default Node.create({
  name: 'doc',
  topNode: true,
  content: '(rule | rulegroup)+',

  addAttributes () {
    return {
      id: {},
      name: {},
      color: {},
      method: {}
    }
  },

  addCommands () {
    return {
      setDocAttr: ({ key, value }) => ({ state, dispatch }) => {
        const tr = state.tr.step(new SetDocAttr(key, value))
        dispatch(tr)
        return true
      },

      focusTextnodeByIndex: (index) => ({ chain, state }) => {
        let textNodeIndex = -1
        let foundPos
        let foundNode

        state.doc.descendants((ruleNode, nodePos, nodeIndex) => {
          if (foundNode) return false

          if (ruleNode.isText) {
            textNodeIndex += 1
          }

          if (textNodeIndex === index) {
            foundNode = ruleNode
            foundPos = nodePos
          }
        })

        return chain()
          .focus()
          .setTextSelection(foundPos)
          .scrollIntoView()
          .run()
      }
    }
  }
})
