import { Node } from '@tiptap/core'

export default Node.create({
  name: 'rulegroup',
  content: 'rule+',
  renderHTML ({ HTMLAttributes }) {
    return ['div', { ...HTMLAttributes, class: 'rulegroup' }, 0]
  },
  parseHTML () {
    return [
      {
        tag: 'div',
        getAttrs: node => node.classList.contains('rulegroup')
      }
    ]
  }
})
