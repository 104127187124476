import { Extension } from '@tiptap/core'

export default Extension.create({
  name: 'customKeymap',
  addOptions () {
    return {
      escapeKeyMethod: () => false
    }
  },

  addKeyboardShortcuts () {
    return {
      'Alt-Enter': () => this.editor.commands.toggleWrap('rulegroup'),

      Escape: () => {
        const method = this.options.escapeKeyMethod
        if (typeof method === 'function') {
          this.options.escapeKeyMethod()
        }
        return true
      }
    }
  }
})
