<template lang="pug">
  Scroll(
    v-if='editor'
    ref='scroller'
    @onScroll='updateSymbolMenuPosition'
  )
    SymbolMenu(
      ref='symbolMenu'
      :editor='editor'
      :current-symbol='currentSymbol'
      :editor-is-active='editorIsActive'
    )
    EditorContent.flex.min-h-full.py-6.bg-white(
      :editor='editor'
      :class='{ "w-full min-w-max": editorData.wordWrap }'
    )
</template>

<script>
import debounce from 'lodash/debounce'
import emitter from '@/plugins/emitter'
import Fuse from 'fuse.js'
import { Editor, EditorContent, VueRenderer } from '@tiptap/vue-2'
import HardBreak from '@tiptap/extension-hard-break'
import History from '@tiptap/extension-history'
import DocNode from '@/components/editor/nodes/Doc'
import RuleNode from '@/components/editor/nodes/Rule'
import RuleGroupNode from '@/components/editor/nodes/RuleGroup'
import TextNode from '@/components/editor/nodes/Text'
import SymbolNode from '@/components/editor/nodes/Symbol'
import SuggestionList from '@/components/editor/SuggestionList'
import EditorDecorations from '@/components/editor/decorations'
import SymbolMenu from '@/components/editor/SymbolMenu.vue'
import Keymap from '@/components/editor/keymap'

export default {
  name: 'Editor',

  components: {
    SymbolMenu,
    EditorContent
  },

  props: {

    currentSymbol: {
      type: Object,
      required: true
    },

    editorIsActive: {
      type: Boolean,
      default: true
    },

    editorData: {
      type: Object,
      required: true
    },

    allSymbols: {
      type: Array,
      required: true
    },

    savedRules: {
      type: Object,
      required: false
    },

    startSymbolId: {
      type: String,
      required: true
    },

    keymapConfig: {
      type: Object,
      required: false
    },

    handleOpenSymbol: {
      type: Function,
      required: false
    }
  },

  mounted () {
    this.initEditor()
    emitter.on('editorEvent-' + this.editorData.id, this.handleEmitterEvent)
  },

  beforeDestroy () {
    emitter.off('editorEvent-' + this.editorData.id, this.handleEmitterEvent)
    this.editor.destroy()
  },

  data () {
    return {
      editor: null,
      debounceUpdateContent: debounce(this.updateContent, 500)
    }
  },

  computed: {
    savedRulesSuggestionItems () {
      if (!this.savedRules) {
        return
      }

      // map symbol ids and create suggestions
      let result = []
      const keys = Object.keys(this.savedRules)
      for (let i = 0, l = keys.length; i < l; i++) {
        const symbolId = keys[i]
        const symbol = this.allSymbols.find(s => s.id === symbolId)

        if (!symbol) {
          continue
        }

        const { id, name, color } = symbol
        const savedItems = Object.keys(this.savedRules[symbolId])
          .map(key => ({ id, name, color, save: key }))
        result = result.concat(savedItems)
      }

      return result
    },

    suggestionItems () {
      let result = this.allSymbols
        .map(({ id, name, color }) => ({ id, name, color }))

      if (this.savedRulesSuggestionItems.length) {
        result = this.savedRulesSuggestionItems.concat(result)
      }

      result = result.filter(s =>
        s.id !== this.startSymbolId &&
        s.id !== this.currentSymbol.id
      )

      return result
    }
  },

  methods: {

    initEditor () {
      this.editor = new Editor({
        autofocus: false,
        enableInputRules: false,
        // enablePasteRules: false,

        editorProps: {

          scrollMargin: 50,

          attributes: {
          //   spellcheck: false
            class: 'min-w-full min-h-full'
          },

          handleDOMEvents: {
            focus: (...params) => this.$emit('focus', ...params),
            blur: (...params) => this.$emit('blur', ...params)
          }
        },

        extensions: [
          // tiptap extensions:
          History,
          HardBreak,

          // app extensions
          DocNode,
          RuleNode,
          RuleGroupNode,
          TextNode,
          Keymap.configure(this.keymapConfig),
          SymbolNode.configure({
            openSymbolMethod: this.handleOpenSymbol,
            suggestion: {
              char: '#',
              prefixSpace: false,
              decorationClass: 'border-b-2 border-gray-200',
              command: ({ editor, range, props }) => {
                // increase range.to by one when the next node is of type "text"
                // and starts with a space character
                const nodeAfter = editor.view.state.selection.$to.nodeAfter
                const overrideSpace = nodeAfter?.text?.startsWith(' ')

                if (overrideSpace) {
                  range.to += 1
                }

                editor
                  .chain()
                  .focus()
                  .insertContentAt(range, [
                    {
                      type: 'symbol',
                      attrs: props
                    },
                    {
                      type: 'text',
                      text: ' '
                    }
                  ])
                  .run()

                // emit create new symbol event
                if (props.createNew && props.createNew === true) {
                  delete props.createNew
                  this.$emit('createSymbol', props)
                }
              },
              allow: ({ editor, range }) => {
                return editor.can().insertContentAt(range, { type: 'symbol' })
              },
              items: ({ query }) => {
                const items = this.suggestionItems

                if (!query) {
                  return items
                }

                const results = new Fuse(items, {
                  threshold: 0.3,
                  keys: ['save', 'name']
                })
                  .search(query)
                  .map(i => i.item)

                // prepend "create new" button
                // if query not already in list
                if (!results.some(i => i.name === query)) {
                  results.unshift({
                    name: 'Create new ✏️',
                    createNew: query
                  })
                }

                return results
              },
              render: () => {
                let component
                let popup
                return {
                  onStart: props => {
                    component = new VueRenderer(SuggestionList, {
                      parent: this,
                      propsData: props
                    })
                    popup = this.$tippy('body', {
                      getReferenceClientRect: props.clientRect,
                      appendTo: () => document.body,
                      content: component.element,
                      showOnCreate: true,
                      interactive: true,
                      trigger: 'manual',
                      placement: 'bottom-start'
                    })
                  },
                  onUpdate (props) {
                    component.updateProps(props)
                    popup[0].setProps({
                      getReferenceClientRect: props.clientRect
                    })
                  },
                  onKeyDown (props) {
                    return component.ref?.onKeyDown(props)
                  },
                  onExit () {
                    popup[0].destroy()
                    component.destroy()
                  }
                }
              }
            }
          }),
          EditorDecorations
        ],

        content: this.createDocFromSymbol(this.currentSymbol),

        onUpdate: this.debounceUpdateContent

      })
    },

    createDocFromSymbol (symbol) {
      return {
        type: 'doc',
        attrs: {
          id: symbol.id,
          name: symbol.name,
          color: symbol.color,
          method: symbol.method
        },
        content: symbol.content
      }
    },

    updateContent () {
      this.$emit('update', this.editor.getJSON())
    },

    updateSymbolMenuPosition () {
      if (this.$refs.symbolMenu.isActive) {
        this.$refs.symbolMenu.updatePopupPosition()
      }
    },

    handleEmitterEvent ({ name, values }) {
      switch (name) {
        case 'focusTextnodeByIndex':
          this.editor.commands.focusTextnodeByIndex(values.index)
          break
      }
    },

    focus () {
      this.editor.commands.focus()
    }

  }

}
</script>

<style lang="scss">
.ProseMirror:focus {
  outline: none;
}

.rulegroup {
  @apply border border-gray-300 my-1.5 mx-2.5 py-1;
}

.rule {
  @apply pl-4 pr-7 border-l-3 border-transparent;
  // text indent messes up cursor position when typing "#", removing and typing "#" again :(
  // text-indent: -#{$margin-small};
  // & * {
  //   text-indent: 0;
  // }
  .rulegroup & {
    @apply list-item list-inside list-decimal px-2;
    &::marker {
      @apply text-sm font-bold;
    }
  }
  &--focused {
    @apply border-blue-600 bg-gray-50;
  }
  &--empty::before {
    @apply absolute pointer-events-none text-gray-400 italic;
    content: 'Empty rule';
    .rulegroup & {
      content: 'Empty variant';
    }
  }
  // &--empty:first-child::before {
  //   content: 'Type something or press "#" on your keyboard to add a symbol.';
  //   .rulegroup & {
  //     content: 'Empty variant';
  //   }
  // }
}

.symbol {
  @apply z-0 truncate h-5 cursor-pointer select-auto;
  background-color: var(--color);
  max-width: 9.375rem; // 150px
  white-space: nowrap!important; // overwrite tiptap injected css
  // box-shadow: inset 0 -4px 0 var(--color);
  &.ProseMirror-selectednode {
    @apply z-10 select-none bg-transparent text-black;
    // box-shadow: 0 0 18px -3px var(--color);
    box-shadow: 0 0 0px 2px var(--color), 0 0 18px -3px var(--color);
    // outline: 2px solid var(--color);
  }
  &__dot {
    // @apply inline relative -top-2.5 -right-px -mr-0.5 w-2 h-2;
    @apply inline relative -top-2.5 right-px -mr-1 w-2 h-2;
  }
}
</style>
