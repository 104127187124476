import { Extension } from '@tiptap/core'
import { Plugin } from 'prosemirror-state'
import { Decoration, DecorationSet } from 'prosemirror-view'

export default Extension.create({
  addProseMirrorPlugins () {
    return [
      new Plugin({
        props: {
          decorations: ({ doc, selection }) => {
            const { anchor } = selection
            const decorations = []

            if (!this.editor.isEditable) {
              return
            }

            doc.descendants((node, pos) => {
              const nodeName = node.type.name
              const hasAnchor = anchor >= pos && anchor <= (pos + node.nodeSize)
              const isEmpty = !node.isLeaf && !node.textContent && node.childCount === 0

              if (nodeName === 'rule') {
                // set empty CSS class
                if (isEmpty) {
                  decorations.push(
                    Decoration.node(pos, pos + node.nodeSize, {
                      class: 'rule--empty'
                    })
                  )
                }

                // focused CSS class
                if (hasAnchor) {
                  decorations.push(
                    Decoration.node(pos, pos + node.nodeSize, {
                      class: 'rule--focused'
                    })
                  )
                }

                // don't descent further
                return false
              }
            })

            return DecorationSet.create(doc, decorations)
          }
        }
      })
    ]
  }
})
