<template lang="pug">
  draggable(
    ref='tabList'
    tag='nav'
    :group='group'
    draggable='.tab'
    :value='value'
    :force-fallback='true'
    :component-data='tablistComponentData()'
    @input='handleInput'
  )
    button.tab(
      role='tab'
      v-for='tab in value'
      :key='tab.id'
      :ref='tab.id'
      :class='{ "tab--active": activeTabId === tab.id }'
      @click='handleTabClick($event, tab)'
      @click.middle='handleTabClose(tab)'
    )

      span.tab__icon-left(v-if='tab.icon')
        component(:is='tab.icon' :size='18')
      span.tab__label(:title='tab.label')
        | {{ tab.label }}
      span.tab__icon-right.tab__icon-close(
        v-if='tab.canClose === true'
        v-tippy='{ content: "Close (ESC)", placement: "bottom-end", delay: [750, 0], offset: [2, 7] }'
      )
        CloseIcon(:size='18')

    Button(
      v-show='showAddButton'
      slot='footer'
      label='Add Symbol'
      :show-label='false'
      theme='workbench'
      size='small'
      icon='PlusIcon'
      class="ml-2 rounded-md hover:bg-gray-400 hover:bg-opacity-90"
      v-tippy='{ content: "Add Symbol", placement: "bottom-end", delay: [750, 0], offset: [2, 7] }'
      @click='handleAddButtonClick'
    )

</template>

<script>
import draggable from 'vuedraggable'
import { createId } from '@/util'

export default {
  name: 'TabBar',

  components: {
    draggable
  },

  props: {

    value: {
      type: Array,
      required: true
    },

    dataId: {
      type: String,
      required: true
    },

    group: {
      type: String,
      default: () => createId('tabbar')
    },

    showAddButton: {
      type: Boolean,
      default: false
    },

    activeTabId: {
      type: String,
      required: false
    },

    scrollTabIntoView: {
      type: Boolean,
      default: true
    },

    isActive: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    activeTabId: {
      immediate: true,
      handler: function (newTabId) {
        if (this.scrollTabIntoView && newTabId) {
          this.$nextTick(() => {
            // wait until child components have rendered
            // scroll to active tab in tab bar
            if (this.$refs[newTabId] && this.$refs[newTabId].length) {
              this.$refs[newTabId][0].scrollIntoView()
            }
          })
        }
      }
    }
  },

  methods: {
    tablistComponentData () {
      const params = {
        on: {
          start: ($event) => {
            this.$emit('dragStart', { $event, tab: this.value[$event.oldDraggableIndex] })
          },
          end: ($event) => {
            this.$emit('dragEnd', { $event, tab: this.value[$event.oldDraggableIndex] })
          },
          wheel: (e) => {
            if (e.deltaY) {
              this.$refs.tabList.$el.scrollLeft += e.deltaY
              e.preventDefault()
            }
          }
        },
        attrs: {
          role: 'tablist',
          class: `tablist ${this.isActive && 'tablist--active'}`,
          'data-id': this.dataId
        }
      }

      return params
    },

    handleInput ($event) {
      this.$emit('input', $event)
    },

    handleTabClick ($event, tab) {
      // - regular click on entire tab element
      // - middle click on entire tab element
      // - click on close icon
      const target = $event.target
      if (
        tab.canClose &&
        (
          target.classList.contains('tab__icon-close') ||
          target.closest('.tab__icon-close')
        )
      ) {
        this.handleTabClose(tab)
      } else if (target.closest('.tab')) {
        if (tab.id !== this.activeTabId) {
          this.$emit('switchTab', tab)
        } else if (
          // focus tab content, if clicked tab === active tab
          target.classList.contains('tab__label') ||
          target.closest('.tab__label')
        ) {
          this.$emit('focusContent')
        }
      }
    },

    handleTabClose (tab) {
      this.$emit('tabClose', tab)
    },

    handleAddButtonClick () {
      this.$emit('addButtonClick')
    }

  }

}
</script>

<style lang="scss">

.tablist {
  @apply h-12 flex-auto flex flex-nowrap items-center bg-gray-300 overflow-x-auto overflow-y-hidden;
  // hide scrollbar
  scrollbar-width: none; // Firefox
  -ms-overflow-style: none; // IE 10+
  &::-webkit-scrollbar {  display: none; } // Chrome Safari
}

.tab {
  @apply cursor-pointer self-stretch flex justify-center content-center items-center mr-px px-4 last:mr-0;
  @apply focus:outline-none;
  @apply bg-gray-200 text-gray-800 border-t-2 border-gray-300;
  &__label {
    @apply text-sm font-medium overflow-ellipsis overflow-hidden;
    max-width: 8.750rem; // 140px
  }
  &--active { @apply bg-white;}
  &:hover &__icon-close { @apply opacity-100; }
  &__icon-close { @apply opacity-0 rounded-sm hover:bg-gray-300 hover:bg-opacity-90; }
  &--active &__icon-close { @apply opacity-100 hover:bg-gray-100 hover:bg-opacity-90; }
  &__icon-left { @apply mr-1.5 -ml-0.5; }
  &__icon-right { @apply ml-1 -mr-2; }
  .tablist--active &--active { @apply border-blue-600 text-blue-700; }

  &.sortable-ghost { @apply bg-white bg-opacity-75; }
  &.sortable-chosen {}
}

</style>
