<template lang="pug">
  transition-group(
    v-show='isActive'
    tag='div'
    name='list-transition'
    ref='container'
    class='flex'
  )
    .flex(
      v-for='(item, index) in currentMenuItems'
      :key='currentMenuKey + "-" + index'
    )
      template(v-if='item.type === "button"')
        button.relative.cursor-pointer.text-white.text-sm.leading-snug.whitespace-nowrap(
          class='hover:bg-gray-800 px-0.5 focus:outline-none'
          :title='item.title && item.title'
          @click='item.onClick'
        ) {{ item.label }}
          //- hint
          span(
            v-if='item.hint'
            class='absolute top-0 -right-0.5 w-1 h-1 bg-red-500 rounded-full'
          )
      template(v-else-if='item.type === "numberInput"')
        input(
          ref='autofocus-input'
          type='number'
          min='1'
          max='100'
          class='px-0.5 ml-1.5 w-10 focus:outline-none text-black'
          :value='item.value'
          @change='item.onChange'
        )
      template(v-else-if='item.type === "textInput"')
        input(
          :title='item.instruction'
          :placeholder='item.placeholder'
          :maxlength='item.maxlength'
          ref='autofocus-input'
          class='px-0.5 ml-1.5 w-24 focus:outline-none text-black'
          :value='item.value'
          @input='item.onInput'
        )
      template(v-else-if='item.type === "helpLink"')
        a(
          :href='item.url'
          target='_blank'
          title='Help'
          class='text-white hover:text-gray-100 ml-0.5'
        )
          HelpCircleOutlineIcon(
            :size='20'
          )

      //- seperator
      .div(
        v-if='index !== (currentMenuItems.length - 1)'
        class='w-px mx-0.5 bg-gray-700'
      )

</template>

<script>
import { modifiersSchema } from './nodes/Symbol'

export default {
  name: 'SymbolMenu',

  props: {

    editor: {
      type: Object,
      required: true
    },

    editorIsActive: {
      type: Boolean,
      required: true
    },

    currentSymbol: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      currentMenu: 1,
      popup: null,
      menuType: {
        main: 1,
        options: 2,
        grammar: 3,
        case: 4,
        variant: 5,
        save: 6
      },
      menuLabelTrans: {
        en: {
          options: 'Options',
          article: 'Article',
          grammar: 'Grammar',
          plural: 'Pluralize',
          pastTense: 'Past Tense',
          case: 'Case',
          capitalize: 'Capitalize',
          capitalizeAll: 'Capitalize all',
          lower: 'Lowercase',
          variant: 'Variant',
          save: 'Save',
          back: '← Back'
        }
      },
      menuDescriptionTrans: {
        en: {
          article: 'Adds an indefinite article in front',
          // grammar: '',
          plural: 'For nouns: Pluralizes a symbol',
          pastTense: 'For verbs: Converts a symbol to past tense',
          // case: '',
          capitalize: 'Capitalizes the first letter',
          capitalizeAll: 'Capitalizes each letter',
          lower: 'Converts each letter to lowercase',
          save: 'Remember the rule selection'
          // variant: '',
          // back: ''
        }
      },
      menuInstructionTrans: {
        en: {
          save: 'Enter a name to remember the selection by'
        }
      }
    }
  },

  beforeDestroy () {
    this.destroyPopup()
  },

  watch: {

    editorIsActive (newVal) {
      if (!this.popup) return
      if (newVal) this.popup.show()
      else this.popup.hide()
    },

    currentMenu (newVal) {
      this.$nextTick(this.updatePopupPosition)

      // auto focus input elements
      this.$nextTick(() => {
        const input = this.$refs['autofocus-input']
        if (input && input.length && input[0].focus) {
          input[0].focus()
        }
      })
    },

    selection (newVal, oldVal) {
      if (
        this.popup &&
        !newVal.node && oldVal.node
      ) {
        this.destroyPopup()
        this.currentMenu = this.menuType.main
        return
      }

      if (
        this.popup &&
        newVal.from !== oldVal.from
      ) {
        this.updatePopupPosition()
        this.currentMenu = this.menuType.main
        return
      }

      if (
        !this.popup &&
        this.isActive
      ) {
        this.renderPopup()
      }
    }
  },

  computed: {
    lang () {
      return this.$store.state.workbench.lang
    },

    menuLabels () {
      return this.menuLabelTrans[this.lang]
    },

    menuDescriptions () {
      return this.menuDescriptionTrans[this.lang]
    },

    menuInstructions () {
      return this.menuInstructionTrans[this.lang]
    },

    selection () {
      return this.editor.view.state.selection
    },

    symbolNode () {
      const node = this.selection.node
      return node && node.type.name === 'symbol'
        ? node
        : null
    },

    isActive () {
      return this.symbolNode !== null
    },

    modifiers () {
      return this.symbolNode ? this.symbolNode.attrs.mods : {}
    },

    enabledOptions () {
      const options = Object.keys(this.modifiers)

      // add variant attr
      if (this.symbolNode.attrs.variant > 1) {
        options.push('variant')
      }

      // add save attr
      if (this.symbolNode.attrs.save) {
        options.push('save')
      }

      return options
    },

    menus () {
      if (!this.symbolNode) return {}

      return {
        main: [
          {
            label: this.menuLabels.options,
            type: 'button',
            hint: this.enabledOptions.length > 0,
            onClick: () => {
              this.currentMenu = this.menuType.options
            }
          }
        ],

        options: [
          {
            label: this.menuLabels.grammar,
            type: 'button',
            hint: this.enabledOptions.includes('article') ||
              this.enabledOptions.includes('plural') ||
              this.enabledOptions.includes('past'),
            onClick: () => {
              this.currentMenu = this.menuType.grammar
            }
          },
          {
            label: this.menuLabels.case,
            type: 'button',
            hint: this.enabledOptions.includes('capitalize') ||
              this.enabledOptions.includes('capitalizeAll') ||
              this.enabledOptions.includes('lower'),
            onClick: () => {
              this.currentMenu = this.menuType.case
            }
          },
          {
            label: this.menuLabels.variant,
            type: 'button',
            hint: this.enabledOptions.includes('variant'),
            onClick: () => {
              this.currentMenu = this.menuType.variant
            }
          },
          {
            label: this.menuLabels.save,
            type: 'button',
            title: this.menuDescriptions.save,
            hint: this.enabledOptions.includes('save'),
            onClick: () => {
              this.currentMenu = this.menuType.save
            }
          }
        ],

        grammar: [
          {
            label: this.menuLabels.back,
            type: 'button',
            onClick: () => {
              this.currentMenu = this.menuType.options
            }
          },
          {
            label: this.menuLabels.article,
            type: 'button',
            hint: this.enabledOptions.includes('article'),
            title: this.menuDescriptions.article,
            onClick: () => {
              this.toggleModifier('article')
            }
          },
          {
            label: this.menuLabels.plural,
            type: 'button',
            hint: this.enabledOptions.includes('plural'),
            title: this.menuDescriptions.plural,
            onClick: () => {
              this.toggleModifier('plural')
            }
          },
          {
            label: this.menuLabels.pastTense,
            type: 'button',
            hint: this.enabledOptions.includes('past'),
            title: this.menuDescriptions.pastTense,
            onClick: () => {
              this.toggleModifier('past')
            }
          },
          {
            type: 'helpLink',
            url: '/tutorial#symboloptions'
          }
        ],

        case: [
          {
            label: this.menuLabels.back,
            type: 'button',
            onClick: () => {
              this.currentMenu = this.menuType.options
            }
          },
          {
            label: this.menuLabels.capitalize,
            type: 'button',
            hint: this.enabledOptions.includes('capitalize'),
            title: this.menuDescriptions.capitalize,
            onClick: () => {
              this.toggleModifier('capitalize')
            }
          },
          {
            label: this.menuLabels.capitalizeAll,
            type: 'button',
            hint: this.enabledOptions.includes('capitalizeAll'),
            title: this.menuDescriptions.capitalizeAll,
            onClick: () => {
              this.toggleModifier('capitalizeAll')
            }
          },
          {
            label: this.menuLabels.lower,
            type: 'button',
            hint: this.enabledOptions.includes('lower'),
            title: this.menuDescriptions.lower,
            onClick: () => {
              this.toggleModifier('lower')
            }
          },
          {
            type: 'helpLink',
            url: '/tutorial#symboloptions'
          }
        ],

        variant: [
          {
            label: this.menuLabels.back,
            type: 'button',
            onClick: () => {
              this.currentMenu = this.menuType.options
            }
          },
          {
            label: this.menuLabels.variant,
            type: 'numberInput',
            value: this.symbolNode.attrs.variant,
            onChange: ($event) => {
              this.updateSymbolAttrs({ variant: parseInt($event.target.value) })
            }
          },
          {
            type: 'helpLink',
            url: '/tutorial#creatingvariants'
          }
        ],

        save: [
          {
            label: this.menuLabels.back,
            type: 'button',
            onClick: () => {
              this.currentMenu = this.menuType.options
            }
          },
          {
            instruction: this.menuInstructions.save,
            type: 'textInput',
            placeholder: 'Enter a name',
            maxlength: 20,
            // pattern: '^[A-Za-z0-9]{1,5}$',
            value: this.symbolNode.attrs.save,
            onInput: ($event) => {
              const raw = ($event.target.value || '').trim()
              const value = !raw.length ? null : raw
              this.updateSymbolAttrs({ save: value })
            }
          },
          {
            type: 'helpLink',
            url: '/tutorial#savingrules'
          }
        ]
      }
    },

    menuTypeKeys () {
      return Object.keys(this.menuType)
    },

    currentMenuKey () {
      return this.menuTypeKeys.find(k => this.menuType[k] === this.currentMenu)
    },

    currentMenuItems () {
      return this.menus[this.currentMenuKey]
    }

  },

  methods: {
    renderPopup () {
      this.popup = this.$tippy(document.getElementById('app'), {
        getReferenceClientRect: () => {
          const domNode = this.editor.view.nodeDOM(this.selection.from)
          return domNode.getBoundingClientRect()
        },
        appendTo: () => this.$parent.getContentContainer(),
        zIndex: 999,
        interactive: true,
        placement: 'top',
        content: this.$el,
        trigger: 'manual',
        hideOnClick: false,
        triggerTarget: this.$parent.$el,
        showOnCreate: true
      })
    },

    updatePopupPosition () {
      if (this.popup && this.popup.popperInstance) {
        this.popup.popperInstance.update()
      }
    },

    destroyPopup () {
      if (this.popup) {
        this.popup.destroy()
        this.popup = null
      }
    },

    toggleModifier (key) {
      const mods = { ...this.modifiers }
      if (typeof mods[key] === 'undefined') {
        mods[key] = 1
      } else {
        delete mods[key]
      }
      this.editor.commands.updateSymbol({ mods })
    },

    updateModifier (key, value) {
      const mods = { ...this.modifiers }
      if (
        modifiersSchema[key].default === value &&
        typeof mods[key] !== 'undefined'
      ) {
        delete mods[key]
      } else {
        mods[key] = value
      }

      this.editor.commands.updateSymbol({ mods })
    },

    updateSymbolAttrs (data) {
      this.editor.commands.updateSymbol({ ...this.symbolNode.attrs, ...data })
    }
  }
}
</script>
