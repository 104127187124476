import { Node } from '@tiptap/core'
import Suggestion from '@tiptap/suggestion'
import { Plugin } from 'prosemirror-state'
import kebabCase from 'lodash/kebabCase'
import { getContrastColor } from '@/util'

export const modifiersSchema = {
  article: { default: 0 },
  plural: { default: 0 },
  past: { default: 0 },
  capitalize: { default: 0 },
  capitalizeAll: { default: 0 },
  lower: { default: 0 }
}
const schemaKeys = Object.keys(modifiersSchema)

const parseNumberttr = (node, name) => {
  if (!node.hasAttribute(name)) return null
  return parseInt(node.getAttribute(name))
}

export default Node.create({
  name: 'symbol',
  group: 'inline',
  draggable: true,
  inline: true,
  selectable: true,
  atom: true,

  addOptions () {
    return {
      HTMLAttributes: {
        class: 'symbol'
      },
      openSymbolMethod: () => false,
      suggestion: {}
    }
  },

  addAttributes () {
    return {
      id: { default: null },
      name: { default: null },
      color: { default: null },
      variant: { default: 1 },
      save: { default: null },
      mods: { default: {} }
    }
  },

  renderHTML ({ HTMLAttributes, node }) {
    let showRedDot = false
    let hasSaveKey = false
    const nodeAttrs = HTMLAttributes
    const textColorClass = getContrastColor(nodeAttrs.color) === 0
      ? 'text-black'
      : 'text-white'
    const parsedAttrs = {
      class: `${this.options.HTMLAttributes.class} ${textColorClass}`,
      'data-id': nodeAttrs.id,
      style: `--color:${nodeAttrs.color};`
    }

    // parse variant
    if (nodeAttrs.variant !== node.type.attrs.variant.default) {
      parsedAttrs['data-variant'] = nodeAttrs.variant
      showRedDot = true
    }

    // parse save key
    if (nodeAttrs.save !== node.type.attrs.save.default) {
      parsedAttrs['data-save'] = nodeAttrs.save
      showRedDot = true
      hasSaveKey = true
    }

    // parse modifiers
    const modifiers = nodeAttrs.mods
    const keys = Object.keys(modifiers)
    let idx = keys.length
    if (idx) {
      showRedDot = true
      while (idx--) {
        const key = keys[idx]
        const value = modifiers[key]
        if (value !== modifiersSchema[key].default) {
          parsedAttrs['data-' + kebabCase(key)] = value
        }
      }
    }

    const result = [
      'span',
      parsedAttrs
    ]

    // push saved symbol indicator
    if (hasSaveKey) {
      result.push(
        ['img', { src: '/img/pin.svg', width: 14, height: 14, alt: '', class: 'inline -mt-px ml-0.5 ' }],
        ['span', { class: '' }, nodeAttrs.save],
        ['span', { class: 'name ml-0.5' }, `(${nodeAttrs.name})`]
      )
    } else {
      result.push(['span', { class: 'name' }, nodeAttrs.name])
    }

    // push option enabed indicator
    if (showRedDot) {
      result.push(
        ['img', { src: '/img/red-dot.svg', width: 8, height: 8, alt: '', class: 'symbol__dot' }]
      )
    }

    return result
  },

  parseHTML (element) {
    return [
      {
        tag: `span.${this.options.HTMLAttributes.class}[data-id]`,
        getAttrs: node => {
          const result = {
            id: node.getAttribute('data-id'),
            name: node.querySelector('span.name').innerText,
            color: node.style.getPropertyValue('--color')
          }

          // parse variant
          const variant = parseNumberttr(node, 'data-variant')
          if (variant !== null) {
            result.variant = variant
          }

          // parse save key
          const save = node.getAttribute('data-save')
          if (save !== null) {
            result.save = save
          }

          // parse modifiers
          const mods = {}
          let i = schemaKeys.length
          while (i--) {
            const key = schemaKeys[i]
            const value = parseNumberttr(node, 'data-' + kebabCase(key))
            if (value !== null && value !== modifiersSchema[key].default) {
              mods[key] = value
            }
          }

          result.mods = mods

          return result
        }
      }
    ]
  },

  addCommands () {
    return {
      updateSymbol: attrs => ({ state, chain }) => {
        const { node, from } = state.selection

        if (!node) return false

        return chain()
          .updateAttributes(node.type.name, attrs)
          .setNodeSelection(from)
          .run()
      }
    }
  },

  addKeyboardShortcuts () {
    return {
      Backspace: () => this.editor.commands.command(({ tr, state }) => {
        let isMention = false
        const { selection } = state
        const { empty, anchor } = selection

        if (!empty) {
          return false
        }

        state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
          if (node.type.name === this.name) {
            isMention = true
            tr.insertText(this.options.suggestion.char || '', pos, pos + node.nodeSize)

            return false
          }
        })

        return isMention
      })
    }
  },

  addProseMirrorPlugins () {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion
      }),

      new Plugin({
        props: {
          // TODO: open in new panel on SHIFT + CLICK
          handleDoubleClickOn: (view, pos, node, nodePos, event) => {
            if (node.type.name === 'symbol') {
              this.options.openSymbolMethod(node)
              return true
            }
          },

          handleKeyDown: (view, event) => {
            const node = view.state.selection.node
            if (
              event.key === 'Enter' &&
              typeof node !== 'undefined' &&
              node.type.name === 'symbol'
            ) {
              this.options.openSymbolMethod(node)
              return true
            }
          }
        }
      })
    ]
  }
})
